"use client";

import { ReactNode } from "react";
import { ThemeProvider } from "styled-components";
import { DefaultTheme } from "styled-components/dist/types";

// types from styled-components theme provider's prop, "theme" types
type ThemeFn = (outerTheme?: DefaultTheme | undefined) => DefaultTheme;
type ThemeArgument = DefaultTheme | ThemeFn;
interface StyledThemeProviderProps {
  children: ReactNode;
  pagesTheme: ThemeArgument;
}
const StyledThemeProvider = ({
  children,
  pagesTheme
}: StyledThemeProviderProps) => {
  return <ThemeProvider theme={pagesTheme} data-sentry-element="ThemeProvider" data-sentry-component="StyledThemeProvider" data-sentry-source-file="StyledThemeProvider.tsx">{children}</ThemeProvider>;
};
export default StyledThemeProvider;